import { BabyPoopPageApi } from '@hhgtech/hhg-components/build/together/interfaces/types'
import { format, isBefore, parse } from 'date-fns'
import innertext from 'innertext'
import {
  BabyGrowthContent,
  BabyGrowthContentApi,
  BabyGrowthResult,
  BabyGrowthResultApi,
  Result,
  ResultApi,
} from 'interfaces/BabyGrowth'
import { API_DATE_FORMAT, DATE_FNS_LOCALE } from 'interfaces/constants'
import {
  Article,
  ArticleApi,
  AuthorApi,
  Author,
  CategoryApi,
  Category,
  PageCategoryApi,
  PageCategory,
  RecentPostApi,
  RecentPost,
  StaticPageApi,
  StaticPage,
  HealthTool,
  HealthToolApi,
  CategoryHealthToolApi,
  ExpertApi,
  SpotlightApi,
  Expert,
  Spotlight,
  MetaTag,
  MetaTagApi,
  MedicalReviewApi,
  MedicalReview,
  CategoryMetaApi,
  CategoryMeta,
  SponsorApi,
  Sponsor,
  ArticleExpertApi,
  ArticleExpert,
  BMIResultApi,
  BMIResult,
  BMICondition,
  ReviewedCategoryApi,
  ReviewedCategory,
  FAQsApi,
  FAQs,
  ToolContentApi,
  ToolContent,
  SpotlightSummary,
  SpotlightSummaryApi,
  ExpertUserApi,
  ExpertUser,
  ExpertUserSummary,
  ExpertUserSummaryApi,
  ReviewedUserApi,
  HealthToolByCategory,
  CommunityEmbed,
  CommunityEmbedApi,
  SiteWideBanner,
  SiteWideBannerApi,
  AffiliateProductApi,
  AffiliateProduct,
  AffiliateChannel,
  AffiliateCurrency,
  AffiliateProductCategoryApi,
  AffiliateProductCategory,
  SpotlightSuperContentBannerApi,
  SpotlightTemplate,
  SpotlightSuperContentBanner,
  SpotlightVideos,
  SpotlightBannerApi,
  SpotlightBanner,
  HelloHealthGroupCommunityApi,
  CommunityApi,
  HelloHealthGroupCommunityPostApi,
  HomePageCommunityPostApi,
  TogetherTopic,
  TogetherTopicApi,
  TogetherCommunityApi,
  TogetherCommunity,
  OvulationSuggestedArticle,
  OvulationSuggestedArticleApi,
  PagingRecentPost,
  PagingRecentPostApi,
  IExpertPostApi,
  OvulationInfoApi,
  OvulationInfo,
  BabyVaccinationDetail,
  BabyVaccinationDetailApi,
  BabyVaccinationRecord,
  BabyVaccinationStatus,
  BabyVaccinationRecordsApi,
  BreadcrumbApi,
  Breadcrumb,
  OvulationContentApi,
  OvulationContent,
  TogetherPostV2,
  TogetherPostApiV2,
  TogetherPost,
  TogetherPostApi,
  PwgInfoApi,
  PwgInfo,
  PWGResultApi,
  PWGResult,
  WeekOfPregnancyApi,
  WeekOfPregnancyV2,
  BabyPoopPage,
  CommonHealthtoolInfoApi,
  CommonHealthtoolInfo,
  CollectingDataFormApi,
  CollectingDataForm,
  CollectingDataFormType,
  PhoneVerifyType,
  AdditionalQuestionFieldTypeApi,
  AdditionalQuestionFieldType,
  AdditionalInfoApi,
  AdditionalInfo,
} from 'interfaces/types'
import {
  AgeGroup,
  AgeGroupApi,
  BabyVaccinationContent,
  BabyVaccinationContentApi,
  BabyVaccinationResult,
  BabyVaccinationResultApi,
} from 'interfaces/types/BabyVaccination'

export const formatAuthor = (author: AuthorApi): Author => {
  if (!author) return {} as Author

  return {
    displayName: author.display_name || null,
    avatar: author.avatar || null,
    thumbnailAvatar: author.avatar_thumbnail || null,
    profileUrl: author.profile_url || null,
    description: author.description || null,
    metaTag: author.meta_tag || '',
    slug: author?.slug || '',
    role: author?.role || '',
    specialty: author?.specialty || '',
  } as Author
}
export const formatArticleExpert = (
  expert: ArticleExpertApi,
): ArticleExpert => {
  if (!expert) return {} as ArticleExpert

  return {
    id: expert.ID || null,
    name: expert.name || null,
    specialty: expert.specialty || null,
    avatar: expert.avatar || null,
    permalink: expert.permalink || null,
    thumbnailAvatar: expert.avatar_thumbnail || null,
  } as ArticleExpert
}
export const formatSponsor = (sponsor: SponsorApi): Sponsor => {
  if (!sponsor) return {} as Sponsor

  return {
    id: sponsor.id || '',
    title: sponsor.title || '',
    logo: sponsor.logo || '',
    link: sponsor.link || '',
  } as Sponsor
}

export const formatArticle = (article: ArticleApi): Article => {
  if (!article) return {} as Article

  return {
    id: article.ID,
    postName: article.post_name,
    sponsor: article.sponsor ? formatSponsor(article.sponsor) : null,
    isSponsored: article.is_sponsored || null,
    isPremium: article.is_premium || false,
    postTitle: article.post_title || '',
    postModified: article.post_modified || '',
    postDate: article.post_date || '',
    modifiedTime: article.modified_time || '',
    permalink: article.permalink || '',
    template: article.template || '',
    featureImage: article.image || null,
    featureImageMobile: article.image_medium || null,
    featureImageMobileXs: article.image_thumbnail || null,
    videoInfo: article.video_info || null,
    author: formatAuthor(article.author),
    reviewer: article.reviewed_by
      ? formatAuthor(article.reviewed_by) || null
      : null,
    reviewed: article?.reviewed
      ? {
          ...article?.reviewed,
          author: formatReviewer(article?.reviewed?.author),
          editor: formatReviewer(article?.reviewed?.editor),
          expert: formatReviewer(article?.reviewed?.expert),
          fact_check: formatReviewer(article?.reviewed?.fact_check),
        }
      : null,
    category: formatCategory(article.category),
    postContent: article.post_content || null,
    sections: article.sections || null,
    categories: (article.categories || []).map(formatCategory),
    sources: article.sources || null,
    description: article.description || null,
    excerpt: article.excerpt || null,
    expert: formatArticleExpert(article.expert),
    metaTag: article.meta_tag || null,
    spotlight: formatSpotlightsSummary(article?.spotlight),
    keyTakeaways: article?.key_takeaways || null,
    jumpingLink: article?.jumping_link || [],
    breadcrumb: (article?.breadcrumb || []).map(formatBreadcrumb),
  } as Article
}

export const formatUserProfileUrl = (
  user?: ReviewedUserApi | ExpertUserSummaryApi | Author | null,
): string => {
  if (typeof user === 'undefined' || !user) return ''

  return user?.turn_off_profile
    ? ''
    : user?.slug
    ? ['expert', 'medical'].includes(user?.role || '')
      ? `/expert/${user?.slug}`
      : `/author/${user?.slug}`
    : user?.profile_url || user?.permalink || '#'
}

export const formatReviewer = (
  user: ReviewedUserApi | null | undefined,
): ReviewedUserApi | null | undefined => {
  if (typeof user === 'undefined' || !user) return null
  return {
    ...user,
    user_id: user.user_id || user.ID,
    profile_url: formatUserProfileUrl(user),
  }
}

export const formatCategory = (category: CategoryApi): Category => {
  if (!category) return {} as Category

  return {
    termId: category.term_id || null,
    sponsor: category.sponsor ? formatSponsor(category.sponsor) : null,
    isSponsored: category.is_sponsored || null,
    categoryName: category.cat_name || category.name || null,
    name: category.name || null,
    iconUrl: category.icon || '',
    slugName: category.category_nicename || null,
    description: category.description || null,
    featureImage: category.thumbnail || category.image || null,
    displayName: category.name || null,
    parent: category.parent || null,
    permalink: category.permalink || null,
    slug: category.slug || null,
    childCates:
      category.child_cates?.map((childCate) => {
        return formatCategory(childCate)
      }) || [],
    thumnailColor:
      category.thumnail_color ||
      ['#FFEAF1', '#E0E9FB', '#FFF9EB', '#F2FCF7', '#F3F3F3'][
        (Math.random() * 5) | 0
      ],
    colorFeatureImage: category.color_feature_image || null,
    imageColor: category.image_color || null,
    textColor: category.text_color || null,
    catParent: category.cat_parent?.name
      ? {
          name: category.cat_parent.name,
          permalink: category.cat_parent.permalink,
        }
      : {},
    metaTag: category.meta_tag || '',
    reviewedBy: category.reviewed_by
      ? formatReviewedCategory(category.reviewed_by)
      : null,
    reviewedDate: category.reviewed_date || '',
    faqs: category.faqs
      ? category.faqs.map((faq) => formatFaqsCategory(faq))
      : null,
    customizedUrl: category.customized_url || null,
    sponsorInInfinity: category.sponsor_in_infinity
      ? formatSponsor(category.sponsor_in_infinity)
      : null,
  } as Category
}

export const formatBreadcrumb = (data: BreadcrumbApi): Breadcrumb => {
  if (!data) return {} as Breadcrumb

  return {
    termId: data.term_id || null,
    name: data.name || null,
  } as Breadcrumb
}

export const formatCommunityPost = (
  post: HelloHealthGroupCommunityPostApi,
): HomePageCommunityPostApi => {
  return {
    author_avatar: post.user?.avatar ?? '',
    author_full_name: post.user?.name ?? '',
    title: post.name,
    community_name: post.community.name,
    community_slug: post.community.slug,
    preview_image: post.preview_image,
    count_likes: post.likes_count,
    count_comments: post.comments_count,
    count_shares: post.shares_count ?? 0,
    created_at: post.created_at,
    images: post.images,
    preview_description: post.preview_description,
    preview_link: post.preview_link,
    description: post.description,
    liked: post.liked,
    post_id: post.id,
    slug: post.slug,
    created_time: post.created_time,
    topics: post.topics.map((topic) => ({
      topic_id: String(topic.id),
      topic_name: topic.name,
      topic_slug: topic.slug,
    })),
    deleted_at: null,
    schedule_datetime: post.schedule_datetime,

    moderator_avatar: post.user?.role === 'moderator' ? post.user?.avatar : '',
    moderator_id:
      post.user?.role === 'moderator'
        ? post.user?.id
        : post.user?.partner_id ?? 0,
    moderator_name: post.user?.role === 'moderator' ? post.user.name : '',
    created_moderator_id: post.user?.partner_id ?? 0,
    user_email: post.user?.email ?? '',
    is_active: post.is_active,
    author_name: post.user?.name ?? '',
  }
}

export const formatCommunity = (
  community: HelloHealthGroupCommunityApi,
): CommunityApi => {
  return {
    community_id: community.id,
    community_slug: community.slug,
    count_members: community.members_count || 0,
    count_posts: community.posts_count || 0,
    count_topics: community.topics_count || 0,
    description: community.description || '',
    joined: !!community.joined,
    thumbnail: community.thumbnail || '',
    title: community.name || '',
    cover_image: community?.banner?.[0]?.image || '',
    mobile_cover_image: community?.banner?.[0]?.mobile_image || '',
    is_active: community.is_active,
    slug: community.slug,
  }
}

export const formatCommunityEmbed = (
  community?: CommunityEmbedApi | null,
): CommunityEmbed | null => {
  if (typeof community === 'undefined' || !community) return null
  return {
    id: community?.ID,
    title: community?.title || '',
    guestTitle: community?.guest_title || '',
    guestDesc: community?.guest_desc || '',
    memberTitle: community?.member_title || '',
    memberDesc: community?.member_desc || '',
  }
}

export const formatSiteWideBanner = (
  sitewide_banner?: SiteWideBannerApi | null,
  type?: 'article' | 'category' | 'home',
): SiteWideBanner | null => {
  if (typeof sitewide_banner === 'undefined' || !sitewide_banner) return null
  return {
    content: sitewide_banner?.content || '',
    icon: sitewide_banner?.icon || '',
    link: sitewide_banner?.link || '',
    type: type || 'other',
  }
}

export const formatCategoryApi = (category: PageCategoryApi): PageCategory => {
  if (!category) return {} as PageCategory
  return {
    categoryInfo: formatCategory(category.data.category) || null,
    explainersPosts: (category.data.explainers_posts || []).map(formatArticle),
    subCategories: (category.data.sub_categories || []).map(formatCategory),
    experts: category?.data?.experts?.map(formatExpertSummary) || [],
    community: formatCommunityEmbed(category?.data?.community),
    sitewideBanner: formatSiteWideBanner(
      category?.data?.sitewide_banner,
      'category',
    ),
  }
}

export const formatPaging = (paging: PagingRecentPostApi): PagingRecentPost => {
  return {
    currentPage: paging?.current_page || 0,
    perPage: paging?.per_page || 0,
    foundPosts: paging?.found_posts || 0,
    maxNumPages: paging?.max_num_pages || 0,
  }
}

export const formatExpert = (expert: ExpertApi): Expert => {
  return {
    id: expert.ID,
    postName: expert.post_name || '',
    postTitle: expert.post_title || '',
    permalink: expert.permalink || '',
    image: expert.image || '',
    imageThumbnail: expert.image_thumbnail || '',
    postContent: expert.post_content || '',
    organizationText: expert.organization_text || '',
    specialty: expert.specialty || '',
    universityDegree: expert.university_degree || '',
    experience: expert.experience || '',
    medicalLicence: expert.medical_license || '',
    metaTag: expert.meta_tag || '',
    facebookUrl: expert.facebook_url || '',
    zaloUrl: expert.zalo_url || '',
    linkedInUrl: expert.linkedin_url || '',
    twitterUrl: expert.twitter_url || '',
    instagramUrl: expert.instagram_url || '',
    emailAddress: expert.email_address || '',
    youtubeUrl: expert.youtube_url || '',
    lineUrl: expert.line_url || '',
    messenger: expert.messenger || '',
    whatsApp: expert.messenger || '',
    google: expert.google || '',
  }
}

export const formatExpertUser = (expert: ExpertUserApi): ExpertUser => {
  return {
    id: expert.user_id || expert.ID,
    displayName: expert.display_name,
    description: expert.description,
    profileUrl: expert.profile_url,
    avatar: expert.avatar,
    avatarThumbnail: expert.avatar_thumbnail,
    role: expert.role || expert.user_role,
    organisation_url: expert.organisation_url || '',
    fields: expert.fields || {},
    metaTag: expert.meta_tag,
    relatedPost: (expert?.related_post || expert?.articles || []).map(
      formatArticle,
    ),
    turnOffProfile: expert?.turn_off_profile,
    specialty: expert.specialty || '',
    educations:
      expert?.educations?.map((education) => ({
        schoolName: education.school_name,
        study: education.study,
        completedYear: education.completed_year,
      })) || [],
    awardsAccreditations: expert?.awards_accreditations || [],
    experiences: expert?.experiences || [],
    contact: expert?.contact || {},
  }
}

export const formatExpertSummary = (
  expert: ExpertUserSummaryApi,
): ExpertUserSummary => {
  return {
    id: expert.user_id || expert.ID,
    displayName: expert.display_name || '',
    description: expert.description || '',
    profileUrl: formatUserProfileUrl(expert),
    avatar: expert.avatar || '',
    avatarThumbnail: expert.avatar_thumbnail || '',
    role: expert.role || '',
    slug: expert?.slug || '',
    specialty: expert?.specialty || '',
    organisation: expert?.organisation || '',
    organisationUrl: expert?.organisation_url || '',
    turnOffProfile: expert?.turn_off_profile || false,
  }
}

export const formatSpotlightBanner = (
  banner: SpotlightBannerApi,
  type?: SpotlightTemplate,
): SpotlightBanner => {
  if (type === 'super_content') {
    return {
      ...banner,
      items: banner?.items
        ? banner?.items?.map(
            (
              item: SpotlightSuperContentBannerApi,
            ): SpotlightSuperContentBanner => ({
              title: item.title,
              description: item.description,
              position: item.position,
              banner: item?.mobile_banner || item.background,
              desktopBanner: item.background,
            }),
          )
        : [],
    }
  } else {
    return {
      ...banner,
      items: [],
    }
  }
}

export const formatSpotlightVideosURL = (videoURL: string): string => {
  return videoURL.includes('facebook.com') || videoURL.includes('fb.com')
    ? `https://www.facebook.com/plugins/video.php?href=${videoURL}`
    : videoURL.replace('watch?v=', 'embed/')
}

export const formatSpotlightVideos = (
  spotlightVideos: SpotlightVideos,
): SpotlightVideos | null => {
  if (typeof spotlightVideos === 'undefined' || !spotlightVideos) return null
  return {
    ...spotlightVideos,
    videos: spotlightVideos.videos?.map((video) => ({
      video: formatSpotlightVideosURL(video.video),
    })),
  }
}

export const formatSpotlightLandingBanner = (
  banner?: SpotlightLandingPageBannerApi[],
): SpotlightLandingPageBanner => {
  const mainBanner = banner?.[0] || null
  return {
    title: mainBanner?.title || '',
    description: mainBanner?.description || '',
    desktopImage: mainBanner?.desktop_image || '',
    mobileImage: mainBanner?.mobile_image || '',
    buttonText: mainBanner?.button_text || '',
    buttonUrl: mainBanner?.button_url || '',
  }
}

export const formatSpotlightFaqs = (faq: {
  question: string
  answer: string
  readmore: {
    post_title: string
    permalink: string
  }
}): FAQs => {
  if (!faq) return {} as FAQs

  return {
    title: faq?.question,
    content: faq?.answer,
    learnMore: faq?.readmore
      ? {
          title: faq.readmore.post_title,
          permalink: faq.readmore.permalink,
        }
      : null,
  } as FAQs
}

const formatSpotlightWorkshop = (workshop?: {
  title: string
  description: string
  sections: WorkshopApi[]
}): {
  title: string
  description: string
  sections: Workshop[]
} | null => {
  if (!workshop) return null
  return {
    title: workshop?.title || '',
    description: workshop?.description || '',
    sections:
      workshop?.sections?.map((section) => ({
        active:
          typeof section?.inactive_section !== 'boolean' ||
          !section?.inactive_section,
        name: section?.name || '',
        location: section?.location || '-',
        type: section?.type || '',
        time: section?.date
          ? `${format(parse(section.date, 'dd/MM/yyyy', new Date()), 'eeee', {
              locale: DATE_FNS_LOCALE['vi-VN'],
            })}, ${section.date}`
          : '-',
        timeRange: `${section?.start_time} - ${section?.end_time}`,
        image: section?.image || '',
        influencers: section?.influencers,
        campaignId: section?.campaign_id || '',
        locationUrl: section?.location_url || '',
      })) || [],
  }
}

export const formatGameBanner = (game: GameApi): Game => {
  if (!game) return {} as Game

  return {
    desktopBanner: game.desktop_banner || '',
    mobileBanner: game.mobile_banner || '',
    url: game.url || '',
  }
}

export const formatSpotlightGaming = (gaming?: {
  game: GameApi
  mechanism: {
    title: string
    description: string
    steps: MechanismStep[]
  }
  reward: {
    image: string
    items: RewardItems[]
  }
}): {
  game: Game
  mechanism: {
    title: string
    description: string
    steps: MechanismStep[]
  }
  reward: {
    image: string
    items: RewardItems[]
  }
} | null => {
  if (!gaming) return null

  return {
    game: formatGameBanner(gaming?.game) || {},
    mechanism: {
      title: gaming?.mechanism?.title || '',
      description: gaming?.mechanism?.description || '',
      steps: gaming?.mechanism.steps || [],
    },
    reward: {
      image: gaming.reward.image || '',
      items: gaming.reward.items || [],
    },
  }
}

export const formatSpotlightExpert = (
  expert: SpotlightExpertApi,
): SpotlightExpert => {
  return {
    id: expert?.user_id || '',
    displayName: expert?.display_name || '',
    profileUrl: !!expert?.turn_off_profile ? '' : expert?.profile_url || '',
    avatarThumbnail: expert?.avatar_thumbnail || '',
    role: expert?.role || '',
    formerRole: expert?.former_role || '',
    specialty: expert?.specialty || '',
    organisation: expert?.organisation || '',
    organisationUrl: expert?.organisation_url || '',
    turnOffProfile: !!expert?.turn_off_profile,
  }
}

export const formatSpotlight = (spotlight: SpotlightApi): Spotlight => {
  const structuredContent =
    spotlight?.type === 'default' || spotlight?.type === 'landing'
  return {
    id: spotlight.ID,
    postName: spotlight.post_name,
    postTitle: spotlight.post_title,
    permalink: spotlight.permalink,
    startDate: spotlight?.start_date,
    endDate: spotlight?.end_date,
    sponsor: spotlight.sponsor,
    banner: formatSpotlightBanner(spotlight.banner, spotlight?.type),
    community: formatCommunityEmbed(spotlight?.community),
    posts: structuredContent
      ? spotlight.posts.map((section) => ({
          ...section,
          typeView: section.type_view,
          enableVideo: !!section?.enable_video,
          post: section.post.map(formatArticle),
        }))
      : [],
    healthTool: structuredContent ? spotlight.health_tool : '',
    videos: structuredContent ? formatSpotlightVideos(spotlight?.videos) : null,
    metaTag: spotlight.meta_tag,
    isSponsor: spotlight?.is_sponsor,
    type: spotlight?.type || 'default',
    content:
      spotlight?.type === 'super_content' ? spotlight?.content || '' : '',
    sources: spotlight?.sources || '',
    faqs: (spotlight?.faqs?.data || []).map(formatSpotlightFaqs),
    faqsTitle: spotlight?.faqs?.title || '',
    image: spotlight?.image,
    imageMedium: spotlight?.image_medium,
    imageThumbnail: spotlight?.image_thumbnail,
    workshop: formatSpotlightWorkshop(spotlight?.workshop),
    gaming: formatSpotlightGaming(spotlight?.gaming),
    expert: {
      title: spotlight.expert?.title || '',
      experts: spotlight.expert?.experts?.map(formatSpotlightExpert) || [],
    },
    communityTitle: spotlight?.community_title || '',
  }
}

export const formatSpotlightsList = (
  spotligts: SpotlightSummaryApi[],
): SpotlightSummary[] => {
  if (!spotligts) return []
  return spotligts.map((spotlight) => ({
    id: spotlight.ID,
    name: spotlight.name,
    permalink: spotlight.permalink,
    image: spotlight?.small_banner || spotlight?.image || '',
    imageMedium: spotlight?.image_medium || null,
    imageThumbnail: spotlight?.image_thumbnail || null,
    startDate: spotlight?.start_date,
    endDate: spotlight?.end_date,
  }))
}

export const formatSpotlightsSummary = (
  spotlight?: SpotlightSummaryApi,
): SpotlightSummary | null => {
  if (typeof spotlight === 'undefined' || !spotlight) return null
  return {
    id: spotlight.ID,
    name: spotlight.name,
    permalink: spotlight.permalink,
    image: spotlight?.small_banner || spotlight?.image || '',
    imageMedium: spotlight?.image_medium || null,
    imageThumbnail: spotlight?.image_thumbnail || null,
    desktopBanner: spotlight?.desktop_banner || '',
    mobileBanner: spotlight?.mobile_banner || '',
    startDate: spotlight?.start_date,
    endDate: spotlight?.end_date,
  }
}

export const formatRecentPost = (recentPost: RecentPostApi): RecentPost => {
  if (!recentPost) return {} as RecentPost

  return {
    articles: (recentPost.data.articles || []).map(formatArticle),
    posts: (recentPost.data.posts || []).map(formatArticle),
    paging: formatPaging(recentPost.data.paging),
  }
}

export const formatStaticPageData = (data: StaticPageApi): StaticPage => {
  if (!data) return {} as StaticPage

  return {
    id: data.ID,
    postTitle: data.post_title,
    modifiedTime: data.modified_time,
    featureImage: data.feature_image,
    postContent: data.post_content,
    outlinesLink: data?.outlines_link || [],
  }
}

export const formatHealthToolTypeCategory = (
  category: CategoryHealthToolApi,
): HealthToolByCategory => {
  if (!category) return {} as HealthToolByCategory

  return {
    termId: category?.term_id || 0,
    name: category?.name || '',
    healthTools:
      category?.health_tools?.map((tool: HealthTool) =>
        formatHealthTool(tool),
      ) ?? [],
  }
}

export const formatHealthTool = (healthTool: HealthToolApi): HealthTool => {
  if (!healthTool) return {} as HealthTool

  return {
    icon: healthTool.icon || '',
    link: healthTool.link.startsWith('/health-tools')
      ? healthTool.link
      : '/health-tools' + healthTool.link,
    subtitle: healthTool.subtitle || '',
    title: healthTool.title || '',
    template: healthTool.template || '',
    id: healthTool.id || '',
  }
}

export const formatArticleMetaData = (data: MetaTagApi): MetaTag => {
  if (!data) return {} as MetaTag

  return {
    title: data.title || '',
    description: data.description || '',
    locale: data.locale || null,
    url: data.url || null,
    siteName: data.site_name || '',
    publishedTime: data.published_time || '',
    modifiedTime: data.modified_time || '',
    updatedTime: data.updated_time || '',
    image: data.image || '',
    width: Number(data.width || 150),
    height: Number(data.height || 150),
    tags:
      data.tags?.map((tag) => ({
        name: tag.name || '',
        slug: tag.slug || '',
      })) || [],
    openGraph: data.open_graph
      ? {
          title: data.open_graph.title,
          description: data.open_graph.description,
          image: data.open_graph.image,
          imageWidth: data.open_graph.image_width,
          imageHeight: data.open_graph.image_height,
        }
      : {},
    twitter: data.twitter
      ? {
          title: data.twitter.title,
          description: data.twitter.description,
          image: data.twitter.image,
        }
      : {},
  } as MetaTag
}

export const formatCategoryMetaTag = (data: CategoryMetaApi): CategoryMeta => {
  if (!data) return {} as CategoryMeta

  return {
    title: data.title || '',
    description: data.description || '',
    locale: data.locale || null,
    url: data.url || null,
    siteName: data.site_name || '',
    keyword: data.keyword || '',
  } as CategoryMeta
}

export const formatMedicalReviewedData = (
  data: MedicalReviewApi,
): MedicalReview => {
  if (!data.title) return {} as MedicalReview

  return {
    title: data.title,
    description: data.description,
    items:
      data.items?.map((item) => ({
        title: item.title,
        description: item.description,
        imageUrl: item.image_url,
      })) ?? [],
  }
}

export const formatBMIContent = (data: ToolContentApi): ToolContent => {
  if (!data || !data.information) return {} as ToolContent

  return {
    information:
      data.information?.map((info) => ({
        title: info.title,
        description: info.description,
      })) ?? [],
    reviewBy: {
      reviewer: data.reviewed_by?.display_name || data.reviewed_by?.name || '',
      date: data.reviewed_date || '',
      link: formatUserProfileUrl(data?.reviewed_by),
    },
    metaTag: data?.meta_tag || '',
  }
}

export const formatBMIResult = (data: BMIResultApi): BMIResult => {
  if (!data || !data.status) return {} as BMIResult

  return {
    status: data?.status as BMICondition,
    comparison: data?.comparison ?? '',
    assessmentData: [
      {
        tab: 'tools.condition',
        content: data?.condition ?? '',
      },
      {
        tab: 'tools.risks',
        content: data?.risk ?? '',
      },
      {
        tab: 'tools.suggestion',
        content: data?.suggestion ?? '',
      },
    ],
    chartData: data?.chart_data?.length > 0 ? data?.chart_data : [],
    disclaimer: data?.disclaimer ?? '',
    illustrator: data?.illustrator ?? '',
    faqs: data?.faqs ?? [],
    suggestedArticlesLink: data?.suggested_articles_link ?? '',
    suggestedArticles: data?.suggested_articles?.map(formatArticle) ?? [],
  }
}

export const formatReviewedCategory = (
  data: ReviewedCategoryApi,
): ReviewedCategory => {
  return {
    postTitle: data.post_title || '',
    permalink: data.permalink || '',
    displayName: data.display_name || '',
    profileUrl: data.profile_url || '',
    reviewedDate: data.reviewed_date || '',
    avatarThumbnail: data.avatar_thumbnail || '',
  }
}

export const formatFaqsCategory = (data: FAQsApi): FAQs => {
  return {
    title: data.title,
    content: data.content,
  }
}

export const formatOvulationSuggestedArticle = (
  data: OvulationSuggestedArticleApi,
): OvulationSuggestedArticle => {
  if (!data) return {} as OvulationSuggestedArticle

  return {
    suggestedArticles: data?.suggested_articles?.map(formatArticle) ?? [],
    suggestedArticlesLink: data?.suggested_articles_link ?? '',
  }
}
export const formatOvulationInfo = (
  data: OvulationInfoApi | null,
): OvulationInfo => {
  if (!data) return {} as OvulationInfo

  return {
    disclaimer: data.disclaimer,
    info: data.info || [],
    reviewedBy: data.reviewed_by
      ? {
          id: data.reviewed_by?.user_id || data.reviewed_by.ID,
          permalink: formatUserProfileUrl(data?.reviewed_by),
          name: data.reviewed_by?.display_name || data.reviewed_by.name,
          postTitle: data.reviewed_by.post_title,
        }
      : null,
    reviewedDate: data.reviewed_date,
  } as OvulationInfo
}

export const formatLatestArticles = (expertPostsApi: IExpertPostApi) => {
  const expertPosts: Article[] =
    expertPostsApi &&
    expertPostsApi.data?.articles &&
    expertPostsApi.data?.articles.map((post: ArticleApi) => formatArticle(post))
  return {
    articles: expertPosts || [],
    paging: formatPaging(expertPostsApi?.data?.paging),
  }
}

export const formatLocalePrice = (
  price: number | string,
  locale: string,
  currency: string,
): string => {
  const parsePrice = typeof price !== 'number' ? parseFloat(price) : price

  return `${parsePrice.toLocaleString(locale, {
    style: 'currency',
    currency: currency || 'USD',
  })}`
}

export const formatAffiliateChannel = (
  channel: AffiliateChannel,
  currency: AffiliateCurrency,
  locale: string,
): AffiliateChannel => {
  return {
    ...channel,
    price: formatLocalePrice(channel?.price, locale, currency?.code),
  }
}

export const formatAffiliatePriceRange = (
  locale: string,
  currency: AffiliateCurrency,
  channels?: AffiliateChannel[],
): string => {
  if (typeof channels === 'undefined' || channels.length === 0) return ''
  const priceList = channels?.map((channel) => parseFloat(channel?.price))
  const lowestPrice = priceList.reduce((a, b) => (a < b ? a : b))
  const highestPrice = priceList.reduce((a, b) => (a > b ? a : b))
  if (lowestPrice === highestPrice)
    return formatLocalePrice(lowestPrice, locale, currency?.code)
  return `${formatLocalePrice(
    lowestPrice,
    locale,
    currency?.code,
  )} ~ ${formatLocalePrice(highestPrice, locale, currency?.code)}`
}

export const formatAffiliateProductCategories = (
  categories?: AffiliateProductCategoryApi[] | null,
): AffiliateProductCategory[] => {
  if (typeof categories === 'undefined' || !categories) return []
  return categories?.map((category) => ({
    name: category?.name,
    permalink: category?.permalink,
    termId: category?.term_id,
    textColor: category?.text_color,
  }))
}

export const formatAffiliateProductBrand = (
  brands?: AffiliateProductCategoryApi[] | null,
): AffiliateProductCategory[] => {
  if (typeof brands === 'undefined' || !brands) return []
  return (
    brands?.map((brand) => ({
      name: brand?.name || '',
      permalink: brand?.permalink?.includes('https') ? brand.permalink : '',
      termId: brand?.term_id || 0,
      textColor: brand?.text_color || '',
    })) || []
  )
}

export const formatAffiliateProduct = (
  product?: AffiliateProductApi | null,
  locale?: string,
): AffiliateProduct | null => {
  if (typeof product === 'undefined' || !product) return null

  return {
    id: product.ID,
    sku: product?.product_sku || '',
    postName: product?.post_name || '',
    postTitle: product?.post_title || '',
    permalink: product?.permalink || '',
    brand: product?.brand ? formatAffiliateProductBrand([product?.brand]) : [],
    productCat: formatAffiliateProductCategories(product?.product_cat),
    image: product?.image || '',
    imageMedium: product?.image_medium || '',
    imageThumbnail: product?.image_thumbnail || '',
    gallery: product?.gallery,
    channel: product?.channels?.map((channel) =>
      formatAffiliateChannel(
        channel,
        product?.currency_unit,
        locale || 'vi-VN',
      ),
    ),
    currencyUnit: product?.currency_unit,
    priceRange: formatAffiliatePriceRange(
      locale || 'vi-VN',
      product?.currency_unit,
      product?.channels,
    ),
  }
}

export const formatTogetherCommunity = (
  community: TogetherCommunityApi,
): TogetherCommunity => {
  if (!community) return {} as TogetherCommunity
  return {
    name: community?.name || '',
    id: community?.id || 0,
    membersCount: community?.members_count || 0,
    postsCount: community?.posts_count || 0,
    topicsCount: community?.topics_count || 0,
    description: community?.description || '',
    joined: !!community?.joined,
    thumbnail: community?.thumbnail || '',
    coverImage: community?.banner?.[0]?.image || '',
    mobileCoverImage: community?.banner?.[0]?.mobile_image || '',
    isActive: community?.is_active,
    slug: community?.slug || '',
  } as TogetherCommunity
}

export const formatTogetherTopic = (topic: TogetherTopicApi): TogetherTopic => {
  if (!topic) return {} as TogetherTopic
  return {
    categories: topic?.categories,
    community: {
      description: topic?.community.description,
      name: topic?.community.name,
      id: topic?.community.id,
      slug: topic?.community.slug,
    },
    countMembers: topic?.count_members,
    countPosts: topic?.count_posts,
    description: topic?.description,
    id: topic?.id,
    joined: !!topic?.joined,
    thumbnail: topic?.thumbnail,
    isActive: topic?.is_active === 1,
    name: topic?.name,
    order: topic?.order,
    slug: topic?.slug,
  } as TogetherTopic
}

export const formatTogetherPost = (post: TogetherPostApi): TogetherPost => {
  if (!post) return {} as TogetherPost
  return {
    bookmarked: post?.bookmarked,
    comments: post?.comments,
    commentsCount: post?.comments_count,
    community: {
      description: post?.community.description,
      name: post?.community.name,
      id: post?.community.id,
      slug: post?.community.slug,
    },
    likesCount: post?.likes_count,
    createdAt: post?.created_at,
    createdTime: post?.created_time,
    description: post?.description,
    id: post?.id,
    images: post?.images,
    isActive: post?.is_active,
    isPin: post?.is_pin,
    pinDate: post?.pin_date,
    postType: post?.post_type,
    name: post?.name,
    liked: post?.liked,
    previewDescription: post?.preview_description,
    previewImage: post?.preview_image,
    previewLink: post?.preview_link,
    previewTitle: post?.preview_title,
    scheduleDatetime: post?.schedule_datetime,
    slug: post?.slug,
    topics: post?.topics,
    user: {
      avatar: post?.user?.avatar,
      email: post?.user?.email,
      id: post?.user?.id,
      name: post?.user?.name,
      partnerId: post?.user?.partner_id,
      role: post?.user?.role,
      type: post?.user?.type,
      userName: post?.user?.user_name,
    },
    mention: post.mention,
  } as TogetherPost
}

export const formatTogetherPostV2 = (
  post: TogetherPostApiV2,
): TogetherPostV2 => {
  if (!post) return {} as TogetherPostV2
  return {
    bookmarked: post?.bookmarked,
    commented: Boolean(post.commented) || false,
    commentsCount: post?.comments_count,
    community: {
      description: post?.community.description,
      name: post?.community.name,
      id: post?.community.id,
      slug: post?.community.slug,
    },
    likesCount: post?.likes_count,
    createdAt: post?.created_at,
    createdTime: post?.created_time,
    description: post?.description,
    id: post?.id,
    images: post?.images,
    isActive: post?.is_active,
    isPin: !!post?.is_pin,
    pinDate: post?.pin_date,
    postType: post?.post_type,
    title: post?.name,
    liked: post?.liked,
    previewUrl: {
      title: post.preview_title || '',
      url: post.preview_link || '',
      image: post.preview_image || '',
      description: post.preview_description || '',
    },
    scheduleDatetime: post?.schedule_datetime,
    slug: post?.slug,
    topics: post?.topics,
    author: {
      avatar: post?.user?.avatar,
      email: post?.user?.email,
      id: post?.user?.id,
      name: post?.user?.name,
      partnerId: post?.user?.partner_id,
      role: post?.user?.role,
      type: post?.user?.type,
      username: post?.user?.user_name,
    },
    mentions: post?.mentions || [],
    isAnonymous: post?.is_anonymous === 1,
  } as TogetherPostV2
}

export const formatBabyGrowthContent = (
  data: BabyGrowthContentApi,
): BabyGrowthContent => {
  if (!data || !data.information) return {} as BabyGrowthContent

  return {
    information:
      data.information.map((info) => ({
        title: info.title,
        description: info.description,
      })) ?? [],
    reviewBy: {
      reviewer: data.reviewed_by?.display_name || data.reviewed_by?.name || '',
      date: data.reviewed_date || '',
      link: formatUserProfileUrl(data?.reviewed_by),
    },
    metaTag: data.meta_tag || '',
    title: data.title || '',
    description: data.description || '',
    disclaimer: data.disclaimer || '',
    is_sponsored: data.is_sponsored || false,
    sponsor: data.sponsor,
    featuredImage: data.featured_image || '',
  }
}
export const formatBabyGrowthChartResult = (data: ResultApi): Result => {
  if (!data) return {} as Result
  return {
    conditionRiskSuggestion: data?.condition_risk_suggestion,
    faqs: data?.faqs,
    recommendArticles: data?.recommend_articles?.map(formatArticle) || [],
    seeMoreLink: data?.seemore_link,
  }
}
export const formatBabyGrowthRisk = (data: any) => {
  if (!data) return {}

  return {
    '3rd': formatBabyGrowthChartResult(data['3rd']),
    '15th': formatBabyGrowthChartResult(data['15th']),
    '50th': formatBabyGrowthChartResult(data['50th']),
    '85th': formatBabyGrowthChartResult(data['85th']),
    '97th': formatBabyGrowthChartResult(data['97th']),
  }
}
export const formatBabyGrowthResult = (
  data: BabyGrowthResultApi,
): BabyGrowthResult => {
  if (!data) return {} as BabyGrowthResult
  return {
    boy_head: formatBabyGrowthRisk(data?.boy_head),
    boy_height: formatBabyGrowthRisk(data?.boy_height),
    boy_weight: formatBabyGrowthRisk(data?.boy_weight),
    girl_head: formatBabyGrowthRisk(data?.girl_head),
    girl_height: formatBabyGrowthRisk(data?.girl_height),
    girl_weight: formatBabyGrowthRisk(data.girl_weight),
  }
}

export const formatBabyVaccinationRecords = (
  record: BabyVaccinationRecordsApi,
): BabyVaccinationRecord => {
  if (!record) return {} as BabyVaccinationRecord
  let status: BabyVaccinationStatus = 'vaccinated'

  const isVaccinated = record.status
  if (!isVaccinated) {
    const givenOnParsed = parse(record.given_on, API_DATE_FORMAT, new Date())
    status = isBefore(givenOnParsed, new Date()) ? 'passed' : 'next'
  }
  return {
    id: record?.id || '',
    vaccineId: record?.vaccine_id || '',
    vaccineName: record?.vaccine_name || '',
    vaccineAntigen: record?.vaccine_antigen || '',
    givenOn: record?.given_on || '',
    doseNumber: record?.dose_number || 0,
    totalDoses: record?.total_doses || 0,
    status: status,
    optional: record?.vaccine_optional || false,
  } as BabyVaccinationRecord
}
export const formatBabyVaccinationDetail = (
  data: BabyVaccinationDetailApi,
): BabyVaccinationDetail => {
  if (!data) return {} as BabyVaccinationDetail

  return {
    profile: {
      id: data?.profile?.id,
      gender: data?.profile?.gender ? 'male' : 'female',
      name: data?.profile?.name,
      dob: data?.profile?.dob,
      new: data?.profile?.new,
    },
    timelines:
      data?.timelines?.map((timeline) => ({
        id: timeline?.id,
        name: timeline?.name || '',
        shortName: timeline?.short_name || '',
        fromDay: timeline.from_day || 0,
        toDay: timeline.to_day || 0,
        records: timeline.records?.map(formatBabyVaccinationRecords) ?? [],
      })) ?? [],
    notifications:
      data?.notifications?.map((timeline) => ({
        id: timeline?.id,
        name: timeline?.name || '',
        shortName: timeline?.short_name || '',
        records: timeline.records?.map(formatBabyVaccinationRecords) ?? [],
      })) ?? [],
  } as BabyVaccinationDetail
}
export const formatOvulationContent = (
  data: OvulationContentApi,
): OvulationContent => {
  if (!data || !data.information) return {} as OvulationContent

  return {
    information:
      data.information.map((info) => ({
        title: info.title,
        description: info.description,
      })) ?? [],
    reviewBy: {
      reviewer: data.reviewed_by?.display_name || data.reviewed_by?.name || '',
      date: data.reviewed_date || '',
      link: formatUserProfileUrl(data?.reviewed_by),
    },
    metaTag: data.meta_tag || '',
    title: data.title || '',
    description: data.description || '',
    disclaimer: data.disclaimer || '',
    is_sponsored: data.is_sponsored || false,
    sponsor: data.sponsor,
    featuredImage: data.featured_image || '',
  }
}
export const formatUserProfile = (
  user: ReviewedUserApi | null | undefined,
): ReviewedUserApi | null | undefined => {
  if (typeof user === 'undefined' || !user) return null
  return {
    ...user,
    profile_url: formatUserProfileUrl(user),
    ...(user.name && { name: innertext(user.name) }),
    ...(user.display_name && { display_name: innertext(user.display_name) }),
  }
}

export const formatPWGContent = (data: PwgInfoApi | null): PwgInfo => {
  if (!data) return {} as PwgInfo
  return {
    disclaimer: data?.disclaimer || '',
    featuredImage: data?.featured_image || '',
    info: data?.info || [],
    reviewedBy: data.reviewed_by ? formatUserProfile(data.reviewed_by) : null,
    reviewedDate: data?.reviewed_date || '',
    metaTag: data?.meta_tag || '',
    pregnancyNavigation: data?.pregnacy_navigation || null,
    isLeadgen: data?.is_leadgen,
    // isSkippable: data?.is_skippable || undefined,
    isSponsored: data?.is_sponsored,
    sponsor: data?.sponsor,
    title: data?.title || '',
  } as PwgInfo
}
export const formatPWGResult = (data: PWGResultApi): PWGResult => {
  if (!data) return {} as PWGResult

  return {
    assessmentData: [
      {
        tab: 'pregnancyWeightGain.explaination',
        defaultMessage: 'Explaination',
        dataEventCategory: 'Health Tool',
        dataEventAction: 'Result Explanation Click',
        dataEventLabel: window.location.href,
        content: data?.explaination ?? '',
      },
      {
        tab: 'pregnancyWeightGain.suggestion',
        defaultMessage: 'Suggestion',
        dataEventCategory: 'Health Tool',
        dataEventAction: 'Recommendation Click',
        dataEventLabel: window.location.href,
        content: data?.suggestion ?? '',
      },
    ],
    faqs: data?.faqs ?? [],
    suggestedArticlesLink: data?.suggested_articles_link ?? '',
    suggestedArticles: data?.suggested_articles?.map(formatArticle) ?? [],
    disclaimer: data?.disclaimer ?? '',
    weightByWeek:
      data.weight_by_week?.map((w) => ({
        week: w.week,
        min: w.min,
        max: w.max,
      })) ?? [],
    care: data.care,
    pregnancyNavigation: data?.embeb.pregnacy_navigation || [],
    status: data?.status,
  }
}

export const formatPregnancyWeek = (
  pregnancyProgress: WeekOfPregnancyApi,
  index: number,
): WeekOfPregnancyV2 | null => {
  if (!pregnancyProgress?.title) return null
  return {
    id: index,
    name: pregnancyProgress.title,
    fruit_icon: pregnancyProgress.fruit_icon,
    fruitName: pregnancyProgress.fruit_name,
    icon: pregnancyProgress.icon,
    height: pregnancyProgress.height,
    weight: pregnancyProgress.weight,
    description: pregnancyProgress.content || '',
    cleanDescription: pregnancyProgress.clean_content || '',
    permalink: pregnancyProgress?.recommended_article?.permalink || '',
  }
}

export const formatBabyPoopPage = (
  data: BabyPoopPageApi,
): BabyPoopPage | null => {
  if (!data || !Object.keys(data).length) {
    return null
  }

  const _formatSponsorBanner = (
    sponsorBannerApi: BabyPoopPageApi['sponsor_banner'],
  ): BabyPoopPage['sponsorBanner'] => {
    if (!sponsorBannerApi) {
      return null
    }

    return {
      desktopImage: sponsorBannerApi.desktop_image,
      mobileImage: sponsorBannerApi.mobile_image,
      showSponsorBanner: sponsorBannerApi.show_sponsor_banner,
      videoBlock: sponsorBannerApi.video_block
        ? {
            title: sponsorBannerApi.video_block.title,
            listVideo: (sponsorBannerApi.video_block.list_video || []).map(
              (v) => {
                return {
                  title: v.title,
                  videoUrl: v.video_url,
                }
              },
            ),
          }
        : null,
    }
  }

  const _formatReviewer = (
    reviewApi: BabyPoopPageApi['reviewed_by'],
  ): BabyPoopPage['reviewedBy'] => {
    if (!reviewApi) return null

    return {
      ID: reviewApi.ID,
      userId: reviewApi.user_id,
      formerRole: reviewApi.former_role,
      role: reviewApi.role,
      slug: reviewApi.slug,
      displayName: reviewApi.display_name,
      description: reviewApi.description,
      profileUrl: reviewApi.profile_url,
      avatar: reviewApi.avatar,
      avatarThumbnail: reviewApi.avatar_thumbnail,
      turnOffProfile: reviewApi.turn_off_profile,
      specialty: reviewApi.specialty,
      organisation: reviewApi.organisation,
      organisationUrl: reviewApi.organisation_url,
    }
  }

  /**
   * Format result object into an Array
   * @param resultApi - result object returned by api
   * @returns an Array of formattedResultItem
   */
  const _formatResult = (
    resultApi: BabyPoopPageApi['result'],
  ): BabyPoopPage['result'] => {
    const formattedResult: BabyPoopPage['result'] = []

    for (const poopType in resultApi) {
      if (!resultApi.hasOwnProperty(poopType)) {
        continue
      }

      const item = resultApi[poopType]
      formattedResult.push({
        name: poopType,
        image: item.image || '',
        color: item.color || '',
        textInput: item.text_input || '',
        titleResult: item.title_result,
        description: item.description,
        whatShouldIDo: item.what_should_i_do,
        faqs: item.faqs || [],
        recommendArticles: (item.recommend_articles || []).map(
          formatArticle as any,
        ),
      })
    }

    return formattedResult
  }

  return {
    title: data.title,
    englishName: data.english_name,
    description: data.description,
    featuredImage: data.featured_image,
    isSponsored: data.is_sponsored,
    sponsor: data.sponsor,
    sponsorBanner: _formatSponsorBanner(data.sponsor_banner),
    disclaimer: data.disclaimer,
    information: data.information,
    toolGuideline: data.tool_guideline,
    // care: data.care,
    reviewedBy: _formatReviewer(data.reviewed_by),
    reviewedDate: data.reviewed_date || '',
    content: data.content,
    result: _formatResult(data.result),
    isLeadgen: data.is_leadgen,
    metaTag: data.meta_tag,
  } as BabyPoopPage
}

export const formatBabyVaccinationContent = (
  data: BabyVaccinationContentApi,
): BabyVaccinationContent => {
  if (!data) return {} as BabyVaccinationContent

  return {
    information:
      data.info?.map?.((info) => ({
        title: info?.title,
        description: info?.description,
      })) ?? [],
    reviewBy: {
      avatar: data.reviewed_by?.avatar_thumbnail || '',
      reviewer: data.reviewed_by?.display_name || data.reviewed_by?.name || '',
      date: data?.reviewed_date || '',
      link: formatUserProfileUrl(data?.reviewed_by),
    },
    metaTag: data?.meta_tag || '',
    disclaimer: data?.disclaimer || '',
    is_sponsored: data?.is_sponsored || false,
    sponsor: data?.sponsor || {},
    featuredImage: data?.featured_image || '',
    isLeadgen: Boolean(data.is_leadgen),
    pregnancyNavigation: data?.pregnacy_navigation || null,
    ...formatCommonHealthtoolForm(data),
  }
}

export const formatCommonHealthtoolForm = (
  data: CommonHealthtoolInfoApi,
): CommonHealthtoolInfo => {
  return {
    title: data.title || '',
    description: data.description || '',
    ...formatCollectingForm(data),
    listQuestions: (data.list_questions || []).map(formatAdditionalQuestion),
    additionalInfo: formatAdditionalInfo(data.sponsor_banner),
  }
}

export const formatCollectingForm = (
  data: CollectingDataFormApi,
): CollectingDataForm => {
  return {
    collectingTypes: data?.collecting_types || CollectingDataFormType.NONE,
    collectingData: {
      campaigns: data?.collecting_data?.campaigns || null,
      banner: {
        desktop: data?.collecting_data?.banner?.desktop || '',
        mobile: data?.collecting_data?.banner?.mobile || '',
      },
    },
    phoneVerification: data?.phone_verification || PhoneVerifyType.SMS,
    isSkippable: data?.is_skippable || false,
  }
}

export const formatAdditionalQuestion = (
  data: AdditionalQuestionFieldTypeApi,
): AdditionalQuestionFieldType => {
  if (!data) return {} as AdditionalQuestionFieldType
  return {
    question: {
      id: data.question.id,
      question: data.question.question || '',
      questionHint: data.question.tooltip_question || '',
    },
    answers: data.answers || [],
    hasAnother: Boolean(data.has_another),
    isMulti: Boolean(data.select_multi),
    otherInputPlaceholder: data.placeholder || '',
  }
}
export const formatAdditionalInfo = (
  data: AdditionalInfoApi,
): AdditionalInfo => {
  return {
    title: data?.further_result?.title || '',
    description: data?.further_result?.Content || '',
    image: data?.image || '',
    ctaUrl: data?.further_result?.link?.url || '',
    ctaTitle: data?.further_result?.link?.title || '',
  }
}

export const formatBabyVaccinationResult = (
  data: BabyVaccinationResultApi,
): BabyVaccinationResult => {
  if (!data) return {} as BabyVaccinationResult
  const ageGroups: AgeGroup[] = data.banner?.age_group
    ? data.banner?.age_group?.map(formatAgeGroup)
    : []

  return {
    seeMoreLink: data.see_more_link || '',
    faqs: data.faqs,
    banner: {
      title: data.banner?.title || '',
      boyBanner: data.banner?.boy_banner || '',
      girlBanner: data.banner?.girl_banner || '',
      readMoreUrl: data.banner?.read_more_url || '',
      ageGroup: ageGroups,
    },
    suggestedArticles: data?.suggested_articles?.map(formatArticle) ?? [],
    communityTitle: data.community_title || '',
    community: formatCommunityEmbed(data.community),
    pregnancyNavigation: data.pregnacy_navigation || null,
  }
}

export const formatAgeGroup = (ageGroup: AgeGroupApi): AgeGroup => {
  if (!ageGroup) return {} as AgeGroup
  return {
    id: ageGroup.id,
    name: ageGroup.name,
    parent: ageGroup.parent,
    shortName: ageGroup.short_name,
    fromDay: ageGroup.from_day ? Number(ageGroup.from_day) : null,
    toDay: ageGroup.to_day ? Number(ageGroup.to_day) : null,
  }
}

export const cleanUpUndefined = (obj: any) => {
  return JSON.parse(JSON.stringify(obj))
}
